@import "styles/variables.scss";

.data-card-parent-container {
  display: flex;
}

.content-container {
  height: calc(100vh - 200px);
}

.oee-graph-container {
  display: flex;
}

.oee-graph-last-container {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(2, minmax(600px, 1fr));
  grid-column-gap: 3px;
}

.progress-bar-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

%base-chart {
  display: grid;
  grid-column-gap: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.downtime-chart-container {
  grid-template-columns: minmax(300px, 1fr);
  @extend %base-chart;
}

#productionQuality,
#productiveTime {
  margin-right: 0px !important;
}

.tabs-wrapper-container {
  .ant-tabs-nav {
    background-color: #fff;
    width: auto;
    padding: 4px 8px 4px 8px;
    border: 1px solid $gray-5;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0px;
  }

  .ant-tabs-nav-list {
    background-color: $geekblue-1;
    border-radius: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    justify-content: center;
    align-items: center;

    .ant-tabs-tab {
      display: block;
    }
  }

  .ant-tabs-tab {
    padding: 8px 16px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 0px;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-radius: 24px;
    background-color: $geekblue-6;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 0px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }
}

.oee-guage-container {
  display: grid;
  height: 100%;
  grid-template-columns: 25% 50% 25%;
}
.current-rate-container {
  display: flex;
  flex-direction: column;
  text-align: end;
  padding-top: 70%;
  span {
    font-size: 14px;
    font-weight: 400;
    color: $primary-65;
  }
}
.target-rate {
  display: flex;
  flex-direction: column;
  text-align: initial;
  padding-top: 70%;
  span {
    font-size: 14px;
    font-weight: 400;
    color: $primary-65;
  }
}
