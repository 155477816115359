.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  clear: both;
  border-radius: 12px;
  border: 1px solid;
  width: -webkit-fill-available;
  margin: 0px 4px;

  div {
    .progress-bar-variable {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}