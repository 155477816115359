@import "styles/variables.scss";

.content-header-container {
  width: 100%;
  background: white;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #F0F0F0;
  
  .arrow-left,
  .info-circle,
  .h20 {
    padding-left: 12px;
  }
  .info-circle {
    color: $gray-6;
  }
  .description {
    color: $secondary-45;
    padding-left: 12px;
  }
}
