@import "../../../styles/variables.scss";

.LineOverviewCard-wrapper {
    height: 210px;
    padding: 4px 16px 8px 16px;
    border-radius: 16px;
    border: 1px solid $gray-5;
}

.LineOverviewCard-header-wrapper {
    display: grid;
    height: 44px;
    gap: 16px;
    grid-template-columns: 44px 1fr;
}

.LineOverviewCard-title-container {
    width: 246px;
    height: 44px;
    display: flex;
    flex-direction: column;
}

.LineOverviewCard-table-body-container {
    width: 100%;
    margin-top: 8px;
}

.LineOverview-table-text-container {
    width: 100%;
    display: flex;
    span {
        width: 50%;
    }
    span:nth-child(even) {
        text-align: right;
    }
}
