@import "../../../styles/variables.scss";

.ant-btn {
  height: 24px;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 2px 20px 2px 20px;
  border-radius: 16px;
}
.ant-btn.total {
  background-color: $blue-1;
  border: 1px solid $blue-3;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  span {
    color: $blue-6;
  }
}
.ant-btn.critical {
  background-color: $red-1;
  border: 1px solid $red-3;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  span {
    color: $red-6;
  }
}
.ant-btn.major {
  background-color: $volcano-1;
  border: 1px solid $volcano-3;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  span {
    color: $volcano-6;
  }
}
.ant-btn.minor {
  background-color: $orange-1;
  border: 1px solid $orange-3;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  span {
    color: $orange-6;
  }
}
.ant-btn.warning {
  background-color: $gold-1;
  border: 1px solid $gold-3;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  span {
    color: $gold-6;
  }
}
.ant-btn.event {
  background-color: $yellow-1;
  border: 1px solid $yellow-3;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  span {
    color: $yellow-6;
  }
}
.ant-btn.apply {
  width: 89px;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border: 1px solid $gray-5;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  span {
    color: $secondary-45;
  }
}
.ant-btn.reset {
  width: 88px;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border: 1px solid $gray-5;
  border-radius: 16px;
  justify-content: center;
  display: flex;
  span {
    color: $secondary-45;
  }
}
.ant-btn.download {
  width: 120px;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border: 1px solid $gray-5;
  border-radius: 16px;
  justify-content: center;
  display: flex;
  span {
    color: $secondary-45;
  }
}

.ant-btn.upload {
  width: 90px;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border: 1px solid $gray-5;
  border-radius: 16px;
  justify-content: center;
  display: flex;

  .anticon + span {
    margin-left: 0;
  }

  .anticon-upload svg {
    width: 10.2px;
    height: 10px;
  }
  span {
    color: $secondary-45;
  }
}
.ant-btn.ant-btn-default.assign.reason.code {
  width: 217px;
  height: 37px;
  padding: 3px 12px 3px 12px;
  border: 1px solid $gray-5;
  border-radius: 16px;
  margin-right: 10px;
  span {
    color: $primary-65;
  }
}
.ant-btn.ant-btn-default.edit.reason.code {
  width: 175px;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  span {
    color: $primary-65;
  }
}
.ant-btn.ant-btn-default.end.downtime{
  width: 217px;
  height: 37px;
  padding: 3px 12px 3px 12px;
  border: 1px solid $gray-5;
  border-radius: 16px;
  margin-right: 10px;
  span {
    color: $primary-65;
  }
}

.ant-btn.upload:hover,
.ant-btn.download:hover,
.ant-btn.reset:hover,
.ant-btn.apply:hover {
  span, svg,
  .anticon-upload svg,
  .anticon + span {
    color: #40a9ff;
  }
}
