@import "styles/variables.scss";


.rangepicker {
    width: 356px;
    .ant-picker {
        width: 100%;
        border-radius: 16px;
    }
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item{
    color: $secondary-45;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    .ant-select-item-option-content{
        color: $geekblue-6;
        background-color: $geekblue-1 !important;
        border-right: 2px solid $geekblue-6;
        box-shadow: none;
    }
    
}

.shift{
    color:lightgray;
    align-self: center;
}