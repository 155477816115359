.spinning {
  position: absolute;
  display: flex;
  background: #ffffff75;
  opacity: 1;
  height: 100vh;
  width: 100vw;
  margin: auto;
  z-index: 9999;

  span {
    margin: auto;
  }
}