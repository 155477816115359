@import "styles/variables.scss";
.activealarmCard-main-container {
  border-radius: 16px;
  .ant-table-tbody {
    font-size: 14px;
    font-weight: 400;
  }
  .column-title {
    color: $geekblue-6;
    font-size: 14px;
    font-weight: 500;
  }
}
.activealarmCard-header-wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
  svg {
    margin-top: 5px;
  }
}
.activealarmCard-button-container {
  display: flex;
  grid-column-gap: 5px;
  padding-top: 8px;

  .ant-btn {
    width: 122px;
    height: 24px;
  }
  .card-with-hover {
    width: fit-content;
    .show-on-hover {
      display: none;
    }
  }
  .card-with-hover:hover {
    .show-on-hover {
      display: block;
    }
  }
}
.activealarmCard-data-container {
  margin-top: 8px;
}
.activealarmCard-data-inner-container {
  display: grid;
  grid-template-columns: 136px 120px 120px 178px 1fr;
  color: $geekblue-6;
  margin-top: 8px;
  span {
    color: $geekblue-6;
  }
  .activealarmCard-timestamp {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
.activealarmCard-data-inner-container:nth-last-child(1) {
  margin-bottom: 6px;
}

.ant-table-cell {
  background-color: $gray-1 !important;
  padding: 2px 2px !important;
}
.ant-table-thead > tr > th {
  border-bottom: 0px !important;
}

.ant-table-cell::before {
  height: 0px !important;
}

.ant-table-body {
  width: 99% !important;
}

.ant-table-tbody > tr > td {
  border: 0px !important;
}
.ant-table-cell {
  .ant-btn {
    width: 100%;
    height: 24px;
  }
}
