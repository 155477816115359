@import "styles/variables.scss";

.management-filter-container {
  width: 100%;
  padding: 4px 8px;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid $gray-5;
  align-items: center;
  .ant-btn.ant-btn-default.end.downtime {
    width: 133px !important;
    height: 32px;
    padding: 3px 12px 3px 12px;
    border: 1px solid $gray-5;
    border-radius: 16px;
    margin-left: 0px;
    span {
      color: $secondary-45;
    }
  }
}
.filter-view-container {
  display: flex;
  align-items: center;
}
.resource-view-container {
  .filter-view-container {
    justify-content: end;
  }
}
.view-container {
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $gray-1;
    border-radius: 8px;
    margin: 0 4px;
    background: $geekblue-6;
  }
  label.ant-radio-button-wrapper {
    color: $secondary-45;
    border-radius: 8px;
    margin: 0 4px;
    border: 1px solid $gray-5;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
  }
  .ant-radio-group.ant-radio-group-solid {
    display: flex;
  }
  label.ant-radio-button-wrapper {
    width: 32px;
    display: flex;
    justify-content: center;
  }
}
.view-eye-container {
  color: $secondary-45;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}
.search-container {
  button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
    border: none;
  }
  span.ant-input-group-wrapper.ant-input-search {
    border-radius: 16px;
    border: 1px solid $gray-5;
    height: 32px;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    right: 10px;
    left: unset;
  }
  button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
    height: 15px;
    width: 20px;
  }
}
.button-container {
  justify-content: end;
  display: flex;
  gap: 8px;
  button.ant-btn.ant-btn-default.save.template {
    display: flex;
  }
  button {
    border-radius: 24px;
    span {
      color: $secondary-45;
    }
  }
}
.category-content-container,
.factor-content-container {
  width: 100%;
  margin: 16px 0;
  display: flex;
  height: 148px;
  grid-column-gap: 16px;

  .ant-card.ant-card-bordered.ant-card-small {
    width: 284px !important;
  }
}
.factor-content-container {
  height: 112px;
}
.reason-code-status {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.scroll {
  width: 100%;
  height: 190px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.code-scroll {
  width: 100%;
  height: 270px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.reason-code-content-container {
  width: 100%;
  margin: 16px 0;
  display: flex;
  height: 230px;
  grid-column-gap: 16px;
  // overflow-x: scroll;
  // overflow-y: hidden;
  .ant-card-bordered {
    width: 286px;
  }
  .ant-card-body {
    padding: 16px;
    width: 286px;
  }
  .reason-code-category,
  .reason-code-status {
    padding-bottom: 4px;
    text-align: left;
    .reason-title {
      font-size: 12px;
      font-weight: 400;
      color: $secondary-45;
    }
    .reason-value.status {
      text-align: end;
    }
    .reason-value {
      font-size: 14px;
      font-weight: 400;
      color: $title-85;
      span.Active {
        border: 1px solid $green-3;
        color: $green-6;
        padding: 3px 8px;
        border-radius: 16px;
        width: 50px;
        height: 22px;
        background-color: $green-1;
      }
      span.Inactive {
        border: 1px solid $red-3;
        color: $red-6;
        padding: 3px 8px;
        border-radius: 16px;
        width: 50px;
        height: 22px;
        background-color: $red-1;
      }
    }
  }
}
span.ant-divider-inner-text {
  color: $geekblue-6;
  font-size: 16px;
  font-weight: 500;
}
.grid-view-container,
.expanded-view-container {
  padding: 16px;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 194px);
  .ant-card.ant-card-bordered.selected {
    border: 2px solid $geekblue-6;
  }
  tbody.ant-table-tbody {
    font-size: 14px;
    font-weight: 400;
  }
  td.ant-table-cell.ant-table-selection-column {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border: 1px solid $gray-4 !important;
    border-right: none !important;
  }
  td.ant-table-cell {
    border-top: 1px solid $gray-4 !important;
    border-bottom: 1px solid $gray-4 !important;
    padding: 8px !important;
  }
  td.ant-table-cell:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid $gray-4 !important;
    border-left: none !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background: $geekblue-6 !important;
  }
  th.ant-table-cell {
    background: $geekblue-6 !important;
    color: $gray-1;
    padding: 8px !important;
  }
  span.ant-table-column-title {
    color: $gray-1;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .ant-checkbox-inner {
    border-radius: 11px;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    border-radius: 11px;
    background-color: $geekblue-6;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $geekblue-6;
  }
  span.Active {
    border: 1px solid $green-3;
    color: $green-6;
    padding: 3px 8px;
    border-radius: 16px;
    background-color: $green-1;
  }
  span.Inactive {
    border: 1px solid $red-3;
    color: $red-6;
    padding: 3px 8px;
    border-radius: 16px;

    background-color: $red-1;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: $geekblue-1 !important;
  }
  tr.ant-table-row.ant-table-row-level-0 {
    height: 30px;
  }
  thead.ant-table-thead {
    height: 32px;
  }
  .record {
    .count {
      margin: 8px;
      background-color: $gray-4;
      border-radius: 10px;
      padding: 2px 8px;
      color: $secondary-45;
    }
  }
}
.expanded-view-container {
  tr.ant-table-row-selected > * {
    color: $geekblue-6;
    font-size: 14px;
    font-weight: 400;
    .record {
      .count {
        margin: 8px;
        background-color: $geekblue-6;
        border-radius: 10px;
        padding: 2px 8px;
        color: $gray-1;
      }
    }
  }
}

.filter-button-container {
  margin-left: 8px;
  button.ant-btn.ant-btn-default.filter {
    width: 74px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    gap: 4px !important;
    padding: 6px 12px;
    span {
      font-size: 14px;
      color: $secondary-45;
      margin-left: 0px;
    }
  }
}
