.create-reason-code-category {
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-footer {
    border-top: none;

    button {
      border-radius: 24px;
    }

    .ant-btn-default {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    padding-top: 8px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  label {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.65);
  }

  .input-text {
    margin-top: 4px;
    background: #ffffff;
    box-shadow: inset 1px 0px 0px #f0f0f0, inset -1px 0px 0px #f0f0f0,
      inset 0px 1px 0px #f0f0f0, inset 0px -1px 0px #f0f0f0;
    border-radius: 16px;
  }

  .input-text::placeholder {
    color: rgba(0, 0, 0, 0.45);
    opacity: 1;
  }

  .row {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;

    .ant-select {
      width: 100% !important;
    }
  }

  .status-row {
    display: flex;
    align-items: center;

    label {
      margin-right: 8px;
    }
  }
}
