@import "styles/variables.scss";

.data-card-container {
  border: 1px solid $geekblue-6;
  border-radius: 8px;
  width: 100%;
  margin: 8px;
}

.data-card-header {
  background-color: $geekblue-6;
  color: $geekblue-1;
  height: 32px;
  border-radius: 7px 7px 0px 0px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-card-content {
  height: 32px;
  border-radius: 8px 8px 0px 0px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}