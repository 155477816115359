@import "styles/variables.scss";

.reason-code-form {
  display: flex;
  flex-wrap: wrap;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: $primary-65;
  }

  .ant-input-affix-wrapper {
    background-color: $gray-3 !important;
    height: 40px;
    font-weight: 500;
    font-size: 24px !important;
    line-height: 32px;
    margin-top: 4px !important;
    border-radius: 18px !important;
  }

  .ant-input {
    background-color: $gray-3 !important;
  }
}

.reason-code-metrics-container {
  width: 100%;
}

.metrics-data-card {
  margin-right: 16px;
  width: 227px;
  flex-grow: 1;
  .ant-select-arrow {
    top: 36px;
  }
  .ant-select {
    width: 100% !important;
  }
  .ant-select-selector {
    margin-top: 16px;
    height: 40px !important;
    box-shadow: inset 0px 1px 8px rgba(0, 0, 0, 0.25);
    border-radius: 16px !important;
  }
}

.reason-code-metrics-button-container {
  display: flex;
  width: 100%;
  .ant-btn {
    border-radius: 18px;
    width: 100% !important;
    height: 40px !important;
    margin-right: 16px;
    margin-top: 16px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
  }
}
