@import '../../../styles/variables.scss';

.dataTable-container {

  .trending-container-buttons {
    display: flex;
    padding: 4px 8px;
    background-color: $gray-1;
    box-shadow: inset 0px -1px 0px $gray-4;

    .ant-btn.dataTable-buttons {
      border-radius: 16px;
      margin-right: 8px;
      color: rgba(0, 0, 0, 0.45);
    }

    .time-selector {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .ant-select-selector {
        border-radius: 16px;
      }

      .ant-radio-button-wrapper {
        color: $secondary-45;
      }

      .ant-radio-button-wrapper:first-child {
        border-radius: 16px 0 0 16px;
      }

      .ant-radio-button-wrapper:last-child {
        border-radius: 0 16px 16px 0;
        box-shadow: inset -1px 0 0 #d9d9d9, inset 0 1px 0 #d9d9d9,
          inset 0 -1px 0 #d9d9d9;
      }

      p {
        color: $secondary-45;
        margin: 0;
        margin-right: 4px;
      }

      .ant-picker{
        margin-left: 8px;
        margin-right: 8px;
      }
      .date-picker {
        margin-left: 8px;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 16px;

        .ant-picker-input>input {
          color: $secondary-45;
        }
      }
    }
  }

  .dataTable-container-buttons {
    display: grid;
    grid-template-columns: 84% 16%;
    padding: 4px 8px;
    background-color: $gray-1;
    box-shadow: inset 0px -1px 0px $gray-4;

    .dataTable-container-buttons-time-tools {
      display: flex;

      .dataTable-buttons {
        margin: 0px 8px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .dataTable-container-buttons-screen-tools {
      display: flex;
      justify-content: flex-end;

      .dataTable-buttons-seperator-line {
        width: 1px;
        height: 15px;
        background: rgba(0, 0, 0, 0.06);
        border-radius: 1px;
        position: relative;
        top: 8px;
      }
    }
  }

  .dataTable-container-body {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-column-gap: 8px;
    padding: 8px;
    height: calc(100vh - 179px);
    background-color: $inverse;

    .dataTable-filter-col {
      border-radius: 2px;
      height: calc(100vh - 188px) !important;
      background: $inverse;
    }

    .dark-gray-icon {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.65);
    }

    .dataTable-table-column {
      margin-right: 8px;
      overflow-y: hidden;
      margin-bottom: 16px;

      .ant-table {
        height: calc(100vh - 252px);
      }
    }
  }
}

.modal-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 10px;
}