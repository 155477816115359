@import "../../styles/variables.scss";

.metrics-heading {
  color: $geekblue-6;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.metrics-container {
  display: flex;
  align-items: flex-start;
  padding: 8px;
  justify-content: space-between;
}
.metrics-value {
  size: 14px;
}
