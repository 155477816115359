@import "styles/variables.scss";

.side-bar-container {
  width: 196px;
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  min-height: 968px;
  height: 100%;

  .box-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .fp-box {
      width: 180px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: $gray-1;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      margin-bottom: 32px;
      cursor: pointer;

      .icon {
        margin-bottom: 12px;
      }
    }
  }

  .user-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar {
      width: 96px;
      height: 96px;
      color: $volcano-6;
      background-color: $volcano-2;
    }
    
    .ant-avatar {
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-avatar-string {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
    }

    .user-name-text {
      margin-top: 8px;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $title-85;
    }
  }

  .signout-btn {
    margin-top: 48px;
    background: #f5f5f5;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    height: 56px;

    span {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
