


.table-severity{
    padding: 10px;
}
.column-title-container,.column-activeduration-container{
    .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search .ant-select-selector{
        border-radius: 0px;
    }
}
