@import "../../styles/variables.scss";

.about-us-main {
  .ant-modal-header {
    background-color: #1890FF;

    .ant-modal-title {
      color: #FAFAFA;
    }

    .ant-modal-content {
      .ant-modal-close-x {
        color: #FAFAFA;
      }
    }
  }

  .about-modal {
    border-radius: 2px;

    .info {
      margin-top: 24px;

      span {
        color: $black-2;
      }
    }

    .desc {
      margin-top: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      span {
        color: $black-1;
      }
    }

    .properties-title {
      margin-top: 24px;
    }

    .gray-text {
      color: $black-2;
    }

    .blue-text {
      color: #1890FF;
    }

    .properties {
      margin-top: 19px;
      display: flex;

      .values {
        .blue-text {
          .ant-typography {
            color: #1890FF;
          }
        }
      }

      .keys {
        width: 40%;
        margin-right: 33px;
      }



      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 8px;

          span {
            .blue-text {
              color: #1890FF;
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}