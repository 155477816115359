@import "styles/variables.scss";

.reason-code-metrics-container {
  display: flex;
  align-items: flex-start;
  padding: 8px;
  justify-content: space-between;
  padding: 0px;

  .reason-code-metrics-card {
    width: 100%;
    padding: 8px;

    .reason-code-metrics-heading {
      color: $primary-65;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    .reason-code-metrics-value {
      display: flex;
      align-items: flex-start;
      padding: 4px 16px;
      background: #f5f5f5;
      border-radius: 24px;

      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
  }
}
