.light-grey {
  color: #000 !important;
  width: -webkit-fill-available;
  padding-left: 4px;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  clear: both;
  border-radius: 12px;
  border: 1px solid;
  width: -webkit-fill-available;
}
.green {
  color: #fff !important;
}

.center {
  text-align: center;
}

.progress-bar-variable {
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
}

.progress-bar-value {
  margin-left: 10px;
  margin-right: 10px;
  right: 0px;
  position: absolute;
}
