.progress-container {
  margin-left: 8px;
  margin-right: 8px;
}

.progress {
  background-color: #d8d8d8;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 100%;
  display: flex;
}

.progress-done {
  background: linear-gradient(to left, #f2709c, #ff9472);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.progress-data {
  display: flex;
  place-content: space-between;
}
