@import "styles/variables.scss";

.tabs-wrapper-container {
  .ant-tabs-nav {
    width: auto;
    padding: 4px 8px 4px 8px;
    border: 1px solid #d9d9d9;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0px;
  }
  .ant-tabs-nav-list {
    background-color: #f0f5ff;
    border-radius: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    justify-content: center;
    align-items: center;
    .ant-tabs-tab {
      display: block;
    }
  }
  .ant-tabs-tab {
    padding: 8px 16px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-radius: 24px;
    background-color: #2f54eb;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 0px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }
}

.ant-popover-title {
  min-width: 0px;
}