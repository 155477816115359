@import "styles/variables.scss";

.reason-code-app-container {
  .desctiption-heading {
    color: rgba(0, 0, 0, 0.65);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  .desctiption-container {
    padding: 8px;
  }

  .desctiption-value {
    padding: 4px 16px;
    background: #f5f5f5;
    border-radius: 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .image-container {
    width: 170px;
    margin-left: 16px;
    .status-container {
      float: right;
      margin-right: 8px;
    }
    .image {
      width: 84px;
      height: 84px;
    }
  }

  .running {
    padding: 0px 12px;
    width: fit-content;
    background: #52c41a;
    border-radius: 16px;
  }
  .stopped {
    padding: 0px 12px;
    width: fit-content;
    background: #f14e45;
    border-radius: 16px;
  }

  .status-heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
  }

  .ant-input {
    border-radius: 16px !important;
  }
  .machine-container-outer{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
  .machine-container {
    display: flex;
  }
}

  .reason-code-form-container {
    width: 100%;
    .ant-btn {
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24);
    }
  }

  .table-container {
    td.ant-table-cell.ant-table-selection-column {
      border: 1px solid $gray-4 !important;
      border-right: none !important;
    }
    td.ant-table-cell {
      border-top: 1px solid $gray-4 !important;
      border-bottom: 1px solid $gray-4 !important;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: $primary-65;
    }
    td.ant-table-cell:last-child {
      border: 1px solid $gray-4 !important;
      border-left: none !important;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      border-top-left-radius: 16px !important;
    }
    th.ant-table-cell {
      background: $gray-3 !important;
      color: $gray-1;
    }
    th.ant-table-cell {
      font-size: 24px !important;
      color: $primary-65 !important;
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 16px !important;
    }
    .ant-checkbox-inner {
      border-radius: 11px !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      border-radius: 11px !important;
      background-color: $geekblue-6;
    }
    .ant-checkbox-checked::after {
      border: none;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $geekblue-6;
    }
    span.Active {
      border: 1px solid $green-3;
      color: $green-6;
      padding: 3px 8px;
      border-radius: 16px;
      width: 50px;
      height: 22px;
      background-color: $green-1;
    }
    span.Inactive {
      border: 1px solid $red-3;
      color: $red-6;
      padding: 3px 8px;
      border-radius: 16px;
      width: 50px;
      height: 22px;
      background-color: $red-1;
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: $geekblue-1 !important;
    }
    tr.ant-table-row.ant-table-row-level-0 {
      height: 30px;
    }
    thead.ant-table-thead {
      height: 42px;
    }
    .ant-table-thead > tr > th {
      padding: 2px !important;
    }

    .ant-table-tbody {
      overflow-y: scroll !important;
    }
  }

  .ant-select-selector {
    border-radius: 18px !important;
    background-color: $gray-3 !important;
  }

  .ant-select-dropdown {
    border-radius: 18px !important;
  }

  .filter-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .ant-btn {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.24) !important  ;
    background-color: $gray-3 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    color: $primary-65 !important;
  }

  .ant-select-selection-item {
    background-color: $gray-3 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    color: $primary-65 !important;
  }

  .ant-btn:focus {
    color: $primary-65 !important;
    background-color: $gray-3 !important;
  }

  .up-button {
    width: 100% !important;
    margin-right: 16px;
    background-color: $gray-3 !important;
  }

  .down-button {
    width: 100% !important;
    background-color: $gray-3 !important;
    margin-left: 16px;
  }

  .scroll-button-container {
    display: flex;
    margin-top: 8px;
  }
}
