@import "styles/variables.scss";

.reason-code-tabs {
  .ant-tabs-nav {
    width: auto;
    padding: 4px 8px 4px 8px;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0px;
  }
  .ant-tabs-nav-list {
    border-radius: 24px;
    border: 1px solid $blue-6;
    width: 100%;
    .ant-tabs-tab {
      justify-content: center;
    }
  }
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .ant-tabs-tab {
    padding: 8px 16px;
    width: 100%;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-radius: 24px;
    background-color: $blue-6;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 0px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }
}
