.runtime-data-card-container {
  display: flex;
  justify-content: space-between;
  clear: both;
  border-radius: 12px;
  width: -webkit-fill-available;
  padding: 8px 16px;
  margin: 14px;
  .heading {
    font-size: 14px;
  }
  .value {
    font-weight: 500;
  }
  .percentage-difference {
    font-size: 12px;
  }
  .runtime-right {
    text-align: right;
  }
}
