@import "styles/variables.scss";

.login-container {
  background-image: url("../../assets/images/loginAndPasswordReset.svg");
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.parent-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  text-align: center;
  width: 60%;
  height: 60%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 50% 50%;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .login-rect {
    width: 100%;
    height: 100%;
  }

  .input {
    border-radius: 16px;
    color: rgba(0, 0, 0, 0.25)
  }

  .brabo-image {
    margin-bottom: 40px;
    align-self: center;
  }

  .data-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px 16px;

    .forgot-text {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      color: $secondary-45;
      margin-bottom: 30px;
    }

    .disabled-text {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $disabled;
      margin-bottom: 0px;
    }

    .otp-text {
      text-align: left;
      margin-bottom: 8px;
      p {
        @extend .disabled-text;
      }
    }

    .resend-text{
      text-align: right;
      margin: 8px 0 0 0;
      @extend .disabled-text;
    }

    .resend-otp-link {
      @extend .resend-text;
      color: #057AD1;
      cursor: pointer;
    }

    .login-button {
      margin-top: 30px;
      width: 100%;
      border-radius: 24px;
      place-content: center;
      padding-top: 6px;
      padding-bottom: 6px;
      height: 32px;
      line-height: 20px;
    }

    .session-expiry-text {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 30px;
    }
     
    .refresh-text {
      @extend .disabled-text;
      margin-bottom: 92px;
    }

    .cancel-button {
      @extend .login-button;
      margin-top: 8px;
    }

    .verify-otp {
      @extend .login-button;
      margin-top: 20px;
    }
  }
}

.rectangle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-help-about {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 16px;
  padding-top: 18px;

  .question-mark-circle {
    cursor: pointer;
    align-self: center;
    font-size: 16px;
  }

  svg {
    path {
      fill: $black-1;
    }
  }

  .connect-button {
    margin-right: 16px;

    .ant-tag {
      height: 24px;
      padding: 0.25px 16px;
      border-radius: 24px;
    }
  }

  .clock-wrapper {
    margin-right: 16px;

    .clock-container {
      border: 1px solid $black-1;
      color: $black-1;
      box-sizing: border-box;
      border-radius: 16px;
      height: 24px;
      padding: 5px 9px;
      display: flex;
      align-items: center;

      .anticon-clock-circle {
        margin-right: 6px;
      }

      span {
        color: $black-1;
      }
    }
  }
}

.sign-out {
  cursor: pointer;
}

.blue-title {
  .ant-typography {
    color: #1890FF;
  }
}

.modal-help-warpper {
  width: 100%;
  // height: 98px;

  .ant-popover-content {
    border-radius: 8px;
  }

}