@import "styles/variables.scss";

.upload-excel {

  .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    height: 220px;
    width: 331px;

    .ant-modal-body {
      padding-bottom: 0;
    }
  }
  .ant-modal-footer{
    border: none;
    text-align: center;
    button {
      border-radius: 17px;
    }
    margin-top: 40px;
  }

  .upload-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    p {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $title-85;
      width: 218px;
    }
  }

  .download-sample-template {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 28px;
    cursor: pointer;

    svg {
      color: #2F54EB
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $geekblue-6;
      margin-left: 6px;
      white-space: nowrap;
    }
  }

  .ant-radio-group {

    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-color: rgba(0, 0, 0, 0.45);
      border-width: 2px;
    }

    .ant-radio-checked .ant-radio-inner {
      background-color: #2F54EB;
    }

    .ant-radio-checked .ant-radio-inner::after {
      background-color: #ffffff;
    }

    margin-bottom: 16px;
    label {
      margin-bottom: 28px;
    }
    .ant-radio-wrapper {
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $primary-65;
      }
    }
  }

  .upload-btn {
    border-radius: 30px;
    margin-left: 6px;
  }

  .download-sample-template:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .input-file-upload {
    display: none;
  }

  .file-name {
    position: absolute;
    left: 50px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;

    .file-name-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.5);
      margin: 0;
    }

    .file-close-icon {
      width: 6px;
      height: 6px;
      cursor: pointer;
      margin-left: 6px;
    }
  }
}

.upload-download-container{
  display: flex;
  margin-right: 6px;
  height: 32px;
}