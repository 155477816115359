@import "../../styles/variables.scss";

.LineOverview-card-container-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5px;
  padding-left: 5px;
  padding-right: 8px;
}

.content-container {
  overflow-y: auto;
  height: calc(100vh - 144px);
}

.content {
  span {
    color: $primary-65;
  }
}

.LineOverview-container {
  display: grid;
  grid-template-columns: 374px repeat(2, minmax(500px, 1fr));
  grid-column-gap: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.Lineoverview-charts-bar-container {
  display: grid;
  grid-template-columns: 374px repeat(2, minmax(500px, 1fr));
  grid-column-gap: 5px;
  height: 285px;
  padding-left: 8px;
  padding-right: 8px;
}
