@import "styles/variables.scss";

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

.ant-layout-sider {
  height: -webkit-fill-available;
  overflow-x: hidden;
  position: fixed!important;
  width: 224px !important;
  max-width: 224px !important;
  flex-basis: 224px !important;
  background: $geekblue-6 !important;
  height: calc(100vh - 52px);
}

.ant-layout-sider-trigger {
  width: 224px !important;
  background: $geekblue-6 !important;
}

.ant-layout-sider-collapsed {
  // height: auto;
  max-width: 48px !important;
  width: 48px !important;
  flex-basis: 48px !important;
  min-width: 48px !important;

  .ant-layout-sider-trigger {
    width: 48px !important;
  }

  .ant-layout-sider-trigger::after {
    content: "";
  }
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  height: auto;
}

.switch-module {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #e6f7ff;
  padding: 16px 0;
}

.empty-space {
  flex-grow: 1;
}

.ant-layout-header {
  margin-left: -224px;
  height: 52px !important;
  background: none !important;
  padding: 0 !important;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  margin-top: 52px;
}

.padding-224 {
  padding-left: 224px;
}

.padding-20 {
  padding-left: 48px;
}

.ant-btn:hover {
  color: $geekblue-6;
  border-color: $geekblue-6;
}

.ant-btn-round {
  span {
    color: $secondary-45;
  }
}

.progress-bar-container {
  display: flex;
  justify-content: space-between;
}
