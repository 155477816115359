@import "styles/variables.scss";

.amd-button {
    justify-content: end;
    display: flex;
    gap: 8px;

    button.ant-btn.ant-btn-default.add.reason.code,
    .end.downtime,
    .delete {
        border-radius: 16px;
        color: $secondary-45;

        span {
            color: $secondary-45;
        }
    }
}

.status-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .value{
        
            width: 100%;
            display: flex;
            justify-content: space-between;
            .edit-icon{
                span{
                    svg{
                        color:$geekblue-6;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        
    }
}

.column-title {
    display: flex;
    gap: 8px;
    align-items: center;
}

.shift-container {
    display: flex;
}

.more-container {
    .ant-divider-horizontal {
        margin: 6px 0;
    }

    .ant-divider {
        border-top: 1px solid $gray-4;
    }

    .more-content {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $disabled;
    }

    .delete {
        color: $red-5;
    }

    .inactive {
        color: $geekblue-6;
        border-right: 2px solid $geekblue-6;
    }
}

.add-reasoncode-modal {
    .ant-modal-content {
        border-radius: 16px;
    }

    button.ant-btn.ant-btn-primary {
        border-radius: 16px;
        background: $geekblue-6;
        border-color: $geekblue-6;
    }

    button.ant-btn.ant-btn-default {
        border-radius: 16px;
    }

    svg {
        fill: $gray-13;
    }

    .add-modal-container {
        .title {
            font-weight: 700;
            font-size: 16px;
            padding-top: 8px;
            line-height: 24px;
        }

        .input-container {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-column-gap: 8px;
            padding-top: 8px;

            span {
                color: $primary-65;
            }

            input.ant-input {
                border-radius: 16px;
                border-color: $gray-5;
                color: $gray-5;
            }

            .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
                width: 100% !important;
            }

            .ant-picker {
                border-radius: 16px;
                width: 100%;
            }

            .ant-tree-select {
                width: 100% !important;
            }

            .ant-picker-input {
                flex-direction: initial;
                gap: 8px;
            }

            span.anticon.anticon-clock-circle {
                svg {
                    fill: $gray-6;
                }
            }
        }
    }
}
.sortArrows{
    display: flex;
    flex-direction: column;
    gap:5px;   
   svg{        
        width:11px;
        height:7px;
        cursor:pointer;
        path{
            fill:white;
        }
    }
}
.edit-field{
    input{
    color:#000000A6  !important}
}