@import "styles/variables.scss";

.assignment-tab-container {
    padding: 8px;
    height: calc(100vh - 150px);
    width: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-column-gap: 8px;
    .resource-container {
        padding: 16px;
        border: 1px solid $gray-5;
        .ant-select-selector {
            border-radius: 16px !important;
        }
        .resource-view-container {
            display: grid;
            grid-template-columns: 40% 1fr;
            align-items: center;
            .title {
                color: $title-85;
            }
        }
        .resource-search-container {
            padding-top: 16px;
            display: grid;
            grid-template-columns: 90% 1fr;
            grid-column-gap: 8px;
            .resource-filter {
                text-align: center;
                padding: 5px;
                border: 1px solid $gray-5;
                border-radius: 8px;
                color: $secondary-45;
            }
        }
        .selected-view-container {
            padding-top: 18px;
        }
        .ant-table-wrapper {
            width: 100% !important; 
            min-width: 0px !important;
        }
        .model-table-container {
            height: 655px !important;
            overflow-y: auto;
        }
    }
    .assigned-container {
        padding: 16px;
        border: 1px solid $gray-5;
        .grid-view-container {
            padding: 20px 0px 0px 0px !important;
        }
        .grid-view-container .ant-checkbox-inner {
            border-radius: 0px;
        }
        .assigned-filter {
            display: flex;
            gap: 16px;
            justify-content: end;
            // will be used when fliter button is there
            // display: grid;
            // grid-template-columns: repeat(3, 1fr);
            margin-top: 16px;
            .search-container {
                width: 400px;
            }
            button.ant-btn.ant-btn-default.filter {
                width: 83px;
                border-radius: 24px;
                span {
                    color: $secondary-45;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            button.ant-btn.ant-btn-default.delete {
                width: 90px;
                border-radius: 24px;
                span {
                    color: $secondary-45;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            .ant-typography {
                margin-left: 8px;
            }
        }
        .assigned-input-container {
            margin-top: 14px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 8px;
            height: 62px;
            .input-container {
                span {
                    padding-left: 8px;
                    color: $geekblue-6;
                    font-size: 12px;
                    font-weight: 400;
                }
                input.ant-input {
                    border-radius: 32px;
                    border: 1px solid $geekblue-6;
                }
            }
        }
    }
}
.resource-dropdown {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.assign-modal{
    height: 300px;
    overflow-y: scroll;
}
