@import "styles/variables.scss";

%base-chart {
  display: grid;
  grid-column-gap: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.runtime-card-container {
  display: flex;
}

.table-chart-container {
  display: flex;

  .ant-table-wrapper {
    .ant-table-thead {
      tr {
        th {
          color: $geekblue-6;
        }
      }
    }
  }
}

.ant-table-wrapper {
  min-width: 55% !important;
}

.wrapper-dashboard {
  width: 100%;

  .runtime-statistics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: row;

    .asset-chart-container {
      border: 1px solid $gray-5;
      flex-grow: 0.5;
      border-radius: 12px;
      margin: 4px 2px 2px 2px;
      background-color: $gray-1;
    }

    .asset-title-container {
      flex-grow: 50%;
      padding-left: 5px;
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      justify-content: center !important;
      place-content: space-between;
    }
  }

  .downtime-statistics {
    display: flex;

    .chart-wrapper {
      flex: 0.5;
      display: flex;
    }
  }
}

.multi-guage-chart-container {
  width: 330px;
  height: 220px;
}

.mtrr-chart-container {
  width: 100%;
  height: 86%;
}

.mtrr-data-bar {
  display: flex;
}

.stacked-top-downtime {
  width: 100%;
  height: 230px;
  margin-top: 20px;
}

.machine-timeline{
  width: 100%;
  height: 100%;
  margin-top: 0px;
}

.ant-spin{
 width: 100%;
 margin-top: 100px;
}