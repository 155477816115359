@import "styles/variables.scss";

.tag-filter-heading-container {
  padding: 13.5px 8px 13.5px 8px;

  .main-div-tagfilter {
    display: flex;
    justify-content: space-between;

    .tagFilter-heading {
      display: flex;
      align-items: center;

      .tooltip {
        font-size: 16px;
      }

      span {
        margin-right: 4px;
      }
    }


    .dataVisualisation-btn-add {

      border: 1px solid #1890FF;
      border-radius: 16px;
      color: #1890FF;
    }

    .dataVisualisation-btn-add:hover {

      border: 1px solid #1890FF;
      background-color: #1890FF;
      border-radius: 16px;
      color: white;
    }

    .dataVisualisation-add-btn {
      display: flex;
      align-items: center;
      border: 1px solid $blue-6;
      box-sizing: border-box;
      border: 1px solid #1890FF;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 16px;
      color: #1890FF;
      height: 22px;
      padding: 4px 8px;

      .anticon {
        width: 12px;
      }

      span {

        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .dataVisualisation-add-btn:hover {
      background-color: $blue-6;
      color: $inverse;
      border: 1px solid $blue-6;
      box-sizing: border-box;
      border: 1px solid #1890FF;
      border-radius: 2px;
      height: 22px;
      border-radius: 16px;
      padding: 4px 8px;

      .anticon .anticon-plus {
        color: #1890FF;
        width: 12px;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

.sort-arrow-container {
  display: flex;
  margin-bottom: 5px;
  align-items: center;

  .sort-arrows {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    img {
      cursor: pointer;
      margin-top: 3px;
    }
  }

  .title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 50%;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.ant-select-dropdown {
  border-radius: 16px !important;
}

.tag-filter-options-container {
  .tag-filter-options {
    padding: 4px 8px;
    width: 100%;



    .ant-input-group {
      .ant-input-group-addon:last-child {
        border-radius: 0 8px 8px 0;
      }

      .ant-input-search-button {
        border-radius: 0 8px 8px 0;
      }
    }

    .ant-input-search {
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;


    }

    .ant-input-affix-wrapper:not(:last-child) {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    .ant-input-group {
      .ant-input-group-addon:last-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    .ant-select-selector {
      border-radius: 16px;
    }

    .ant-select-arrow {
      right: 16px;
    }
  }
}


.tag-filter-table-container {
  margin: 4px 8px;
  height: calc(100vh - 345px);
  border: 1px solid rgba(0, 0, 0, 0.06);

  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-thead {
        .ant-table-cell-ellipsis {
          width: 300px
        }
      }
    }
  }
}