@import "styles/variables.scss";

@mixin status($background, $border-color, $color) {
  background: $background !important;
  align-items: center;
  padding: 2px 8px;
  gap: 8px;
  text-align: center;
  border: 1px solid $border-color;
  border-radius: 16px;
  color: $color !important;
}

.statistics-container {
  padding: 4px 8x;

  .table-header {
    .parent {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: 32px;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      border-radius: 16px;
      background-color: #2f54eb;

      .child-col {
        color: white;
        display: flex;
        padding: 4px 16px;

        .title {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        .sort-arrows {
          display: flex;
          flex-direction: column;
          margin-left: 4px;

          span {
            width: 11px;
            height: 11px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .row-wrapper {
    height: 400px;
    overflow-y: scroll;
    .text-box {
      color: rgba(0, 0, 0, 0.65);
      padding: 0 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    .date-hr {
      width: 100%;
      height: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      text-align: center;
    }

    .row-container {
      margin: 2px;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: 28px;
      grid-column-gap: 8px;
      grid-row-gap: 8px;
      border-radius: 16px;
      box-shadow: inset 1px 0px 0px #F0F0F0, inset -1px 0px 0px #F0F0F0, inset 0px 1px 0px #F0F0F0, inset 0px -1px 0px #F0F0F0;

      .running {
        @include status($green-1, $green-3, $green-6);
      }

      .idle {
        @include status($gold-1, $gold-3, $gold-6);
      }

      .stopped {
        @include status($red-1, $red-3, $red-6);
      }

      .row-item {
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding: 0px 16px;
        background-color: white;
        align-self: start;
        white-space: nowrap;

        .eye-btn-container {
          display: flex;
          justify-content: space-between;

          .eye-btn {
            display: grid;
            align-content: center;
            width: 28px;
            height: 28px;
            border: 1px solid #d9d9d9;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
            border-radius: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }
}