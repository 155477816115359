@import "styles/variables.scss";

.about-modal {
  .info {
    margin-top: 24px;
    span {
      color: $black-2;
    }
  }
  .desc {
    margin-top: 8px;
    span {
      color: $black-1;
    }
  }
  .properties-title {
    margin-top: 24px;
  }
  .gray-text {
    color: $black-2;
  }
  .blue-text {
    color: $black-1;
  }
  .properties {
    margin-top: 19px;
    display: flex;
    .keys {
      width: 40%;
      margin-right: 33px;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
