@import "styles/variables.scss";

.dashboard-container {
  background: $inverse;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px 0px;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      img {
        margin-left: 16px;
      }

      .title-text {
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        font-size: 38px;
        line-height: 46px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #057ad1;
        margin: 0 0 0 8px;
      }
    }

    .digital-factory-text {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #ff6700;
    }

    .main-menu-text {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #057AD1;
    }
    
    .center-image {
      margin-bottom: 56px;
      margin-top: 40px;
      height: 34.22vh;
      max-height: 308px;
    }
  }
}
