@import "styles/variables.scss";

.alarmevent-dashboard-wrapper-container {
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 194px);
}
.alarmevent-dashboard-graph-container {
    display: grid;
    // grid-template-columns: repeat(2, minmax(263px, 1fr)) 1fr;
    grid-template-columns: repeat(2, 455px) 1fr;
    grid-column-gap: 5px;
}

.alarmevent-dashboard-devices-row-container {
    display: grid;
    grid-template-columns: repeat((2, minmax(600px, 1fr)));
    grid-column-gap: 5px;
}

.tabs-wrapper-container {
    .ant-tabs-nav {
        background-color: #fff;
        width: auto;
        padding: 4px 8px 4px 8px;
        border: 1px solid $gray-5;
        width: 100%;
    }
    .ant-tabs-tab-btn {
        color: $geekblue-6;
        font-size: 14px;
        font-weight: 500;
    }
    .ant-tabs-top > .ant-tabs-nav {
        margin: 0px;
    }
    .ant-tabs-nav-list {
        background-color: $geekblue-1;
        border-radius: 24px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        justify-content: center;
        align-items: center;
        .ant-tabs-tab {
            display: block;
        }
    }
    .ant-tabs-tab {
        padding: 8px 16px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 0px;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
        border-radius: 24px;
        background-color: $geekblue-6;
    }
    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        width: 0px !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: white;
    }
}

.timeline-container {
    margin-top: 8px;
    .ant-table-wrapper {
        padding: 5px;
    }
    .ant-table-thead > tr > th {
        background-color: $gray-2 !important;
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background-color: $geekblue-1 !important;
    }
}
