@import "styles/variables.scss";

.card-container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    border-bottom: 1px solid $page-bg;
    height: 38px;
    align-items: center;
    .value{
        text-align: end;
        font-size: 12px;
        font-weight: 400;
    }
    .title{
        color: $primary-65;
        font-size: 12px;
        font-weight: 400;
    }
    span.Active {
        border: 1px solid $green-3;
        color: $green-6;
        padding: 3px 8px;
        border-radius: 16px;
        width: 50px;
        height: 22px;
        background-color: $green-1;
    }
    span.Inactive {
        border: 1px solid $red-3;
        color: $red-6;
        padding: 3px 8px;
        border-radius: 16px;
        width: 50px;
        height: 22px;
        background-color: $red-1;
    }
    span.item{
        padding: 2px 8px;
        border-radius: 10px;
        background-color: $geekblue-1;
        color: $geekblue-6;
    }
}
.card-container:last-child{
    border: none;
}
.ant-card-small > .ant-card-head{
    background: $gray-2;
    color: $primary-65;    
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
    padding: 8px 0;
    font-size: 14px;
    font-weight: 500;
    color: $primary-65;  
}
.ant-card.ant-card-bordered.ant-card-small {
    border: 1px solid $gray-4;
}
.ant-card.ant-card-bordered.ant-card-small.selected{
    border: 1px solid $geekblue-6;
    border-radius: 4px;
    .ant-card-head{
        background-color: $geekblue-6;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .ant-card-head-title{
            color: $gray-1;   
        } 
    }
    .ant-card-extra{
        span{
            color: $gray-1;
        }
       
    }
}
.ant-card-small > .ant-card-body {
    padding: 0 8px;
}
.ant-card-head {
    width: 284px !important;
}