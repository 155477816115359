@import "styles/variables.scss";

%base-chart {
  display: grid;
  grid-column-gap: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

%metrics-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.content-container {
  overflow-y: auto;
  height: calc(100vh - 144px);
}
.gauge-chart-container {
  grid-template-columns: 1fr 2fr;
  min-height: 280px;
  grid-template-columns: 1fr 2fr;
  min-height: 280px;
  @extend %base-chart;
  .manufacturing-container{
    display:flex;
    flex-direction: column;
    height: 100%;
     background: rgba(252, 252, 252, 0.8); border: 0.5px solid rgba(0, 0, 0, 0.2); border-radius: 8px;
     .manufacturing-loader-outer{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
     .title{
      display: flex;
      margin: 10px 0px;

      justify-content: center;
     }

.manufacturing-counts{
  height: 100%;
 grid-template-columns: repeat(2, minmax(217px, 1fr));
 @extend %base-chart;
 padding-bottom: 8px;
&__child{
  padding: 8px;
  border: 1px solid $gray-5;
  width: 100%;
  border-radius: 12px;
  margin: 4px 2px 2px 2px;
  background-color: $gray-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:8px;
  .name{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
  }
  .value{
    font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 20px;
  }
}
  
}

  }
.production-container{
   
background: rgba(252, 252, 252, 0.8);
border: 0.5px solid rgba(0, 0, 0, 0.2);
border-radius: 8px;
display: flex;
flex-direction: column;
@extend %base-chart;
.title{
  display: flex;
  margin: 10px 0px;
  justify-content: center;
}
.counter-values{
  height: 100%;
display: flex;
padding-bottom: 8px;
  &__child{
    padding: 8px;
    flex:1;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 4px 2px 2px 2px;
    .counter-section-1{
      height: 100%;
      display: flex;
      justify-content: center;
      &__left,&__right{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap:8px;
      .name{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
        }
        .value{
          font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 20px;
        }
      }
      .vertical-line{
        height:90%;
        width: 1px;
        background-color: $gray-5;
       }
    }
    .counter-section-2{
      height: 100%;
    border-top:  1px solid $gray-5;

      display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap:8px;
        .name{
          font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
        }
        .value{
          font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 20px;
        }
    }
    }
  &__child-2{
    padding: 8px;
    flex:1;
    border: 1px solid $gray-5;
    border-radius: 8px;
    margin: 4px 2px 2px 2px;
    display: flex;
    flex-direction: column;
   .top-outer,.bottom-outer{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    .top-left,.top-right,.bottom-left,.bottom-right{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      gap:8px;
      .name{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
      }
      .value{
        font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 20px;
      }
    }
   }
   .top-outer{
    .vertical-line{
      height:90%;
      display: flex;
      align-items: flex-start;
      &__child{
      height:95%;
      width: 1px;
      background-color: $gray-5;
      }
     
   }

  }
  .bottom-outer{
    .vertical-line{
      height: 100%;
      display: flex;
      align-items: flex-end;
      &__child{
      height:95%;
      width: 1px;
      background-color: $gray-5;
      }
     
  }}
   .horizontal-lines{
    display: flex;
    //justify-content: center;
    &__left{
      height:1px;
      justify-content: flex-start;
      background-color: $gray-5;
      width: 48%;
    }
    &__right{
      height: 1px;
      display: flex;
      justify-content: flex-end;
      background-color: $gray-5;
      margin-left: 4%;
      width: 48%;
    }
   }
  
  }
}
.packaging-loader-outer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}}

}

.multi-guage-container {
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  @extend %base-chart;
}

.production-metrics-container {
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  @extend %base-chart;
}

.pareto-chart-container {
  // grid-template-columns: repeat(2, 1fr);
  // @extend %base-chart;
  display: flex;
  width: 100%;
  grid-column-gap: 5px;
  padding-left: 8px;
  padding-right: 8px;
  #alarmAndErrorSummary,
  #activeAlarm {
    width: 50%;
  }
}

.gauge-chart-metrics-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 36px;
}

.metrics-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.metrics-value-first {
  @extend %metrics-value;
  color: $geekblue-6;
}

.metrics-value-last {
  @extend %metrics-value;
  color: $green-6;
}

.guage-chart-container {
  width: 100%;
  height: 70%;
}

.metrics-container {
  .metrics-value {
    .ant-select-selector {
      border-radius: 15px !important;
    }
  }
}

.production-machine-metrics-container {
  display: flex;
  padding: 0px 8px;
  height:41px;
  .machine-status-container {
    border: 1px solid #F5F5F5;
    border-radius: 12px; 
    padding: 8px;
    width: 100%;
    margin: 2px 2px 2px 2px;
    background-color: $gray-1;
    .title{
      font-weight: 500;
      font-size: 16px;
    }
    .running {
      padding: 0px 10px;
      color:aliceblue ;
      width: fit-content;
      background: #52c41a;
      border-radius: 50%;
      margin-right: 10px
    }
    .stopped {
      padding: 0px 10px;
      width: fit-content;
      background: #f14e45;
      color:aliceblue ;
      border-radius: 50%;
      margin-right: 10px
    }
  }
}