.custom-ant-modal {
  .ant-modal-content {
    border-radius: 0;

    .ant-modal-close-x {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .ant-modal-header {
      background: #1890ff;
      box-shadow: inset -1px 0px 0px #f0f0f0, inset 0px 1px 0px #f0f0f0,
        inset 1px 0px 0px #f0f0f0;
      border-radius: 2px 2px 0px 0px;

      .ant-modal-title {
        color: #fff;
        font-weight: normal;
        display: flex;

        span {
          display: flex;
          align-items: center;
          color: #fafafa;
        }
      }
    }
  }
}