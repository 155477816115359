@import "../../styles/variables.scss";

@mixin fullGraphHeight {
  .ant-card-body {
    height: 95%;

    .graph {
      height: 100%;
    }
  }
}

.ant-select-selector {
  border-radius: 16px;
}

.time-selector {
  .time-selector-select {
    .ant-select-selector {
      border-radius: 16px;
    }
  }

  .ant-select-item-option-content {
    box-shadow: inset -2px 0px 0px #2f54eb;
    background: #f0f5ff;
    color: rgba(0, 0, 0, 0.85);
  }
}

.option-text {
  display: flex;

  .ant-checkbox-wrapper {
    padding-right: 8px;

    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 16px;
      }
    }
  }

  div {
    padding-right: 8px;
  }
}

.trending-container {
  .trending-container-buttons {
    display: flex;
    padding: 4px 8px;
    background-color: $gray-1;
    box-shadow: inset 0px -1px 0px $gray-4;

    .time-selector {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .ant-radio-button-wrapper {
        color: $secondary-45;
      }

      .ant-radio-button-wrapper:first-child {
        border-radius: 16px 0 0 16px;
      }

      .ant-radio-button-wrapper:last-child {
        border-radius: 0 16px 16px 0;
        box-shadow: inset -1px 0 0 #d9d9d9, inset 0 1px 0 #d9d9d9, inset 0 -1px 0 #d9d9d9;
      }

      p {
        color: $secondary-45;
        margin: 0;
        margin-right: 4px;
      }
      .ant-picker{
        margin-left: 8px;
        margin-right: 8px;
      }

      .date-picker {
        margin-left: 8px;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 16px;

        .ant-picker-input > input {
          color: $secondary-45;
        }
      }
    }
  }

  .trending-container-body {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-column-gap: 8px;
    padding: 8px;
    height: calc(100vh - 158px);
    background: #ffffff;
    overflow-y: auto;
    .dark-gray-icon {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.65);
      height: 24px;
    }

    .filter-col {
      box-sizing: border-box;
      border-radius: 2px;
      height: calc(100vh - 195px) !important;
      background: $inverse;
    }

    .graph-col {
      margin-right: 8px;

      .graph-container {
        margin-bottom: 8px;
        border: none;
        box-sizing: border-box;
        border-radius: 2px;
        background: $gray-1;

        .ant-card-head {
          border: none;
        }

        .amcharts-amexport-menu-level-0.amcharts-amexport-right {
          right: 40px;
          color: $mark;

          .amcharts-amexport-label {
            word-break: normal;
            color: $mark;

            :hover {
              color: $mark;
            }
          }
        }

        .ant-card-head {
          background: $gray-1;
        }

        .ant-card-body {
          display: flex;
          height: 410px;

          .empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            color: rgba(0, 0, 0, 0.45);

            .empty-data-icon {
              font-size: 36px;
              color: rgba(0, 0, 0, 0.25);
            }
          }

          .graph {
            width: 100%;
            height: 100%;
          }
        }
      }

      .empty-container {
        background: $gray-2;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
      }

      .icon-btn {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.45);
        cursor: pointer;
        margin: 0px 4px 0px 4px;
      }
    }

    .tags-data-trending {
      max-height: calc(100vh - 697px);
      height: calc(100vh - 651px);
      min-height: calc(100vh - 651px);
      // height: calc(100vh - 200px);
      // overflow-y: auto;
      // border: 1px solid #f0f0f0;
      border-radius: 8px;

      .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;

            .ant-table {
              height: 100%;

              .ant-table-header {
                background: #fafafa;
              }

              .ant-input {
                height: 24px !important;
              }

              .ant-select {
                .ant-select-selector {
                  height: 24px !important;
                  display: flex;
                  align-items: center;
                }

                input {
                  height: 24px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tags-data-trending::-webkit-scrollbar {
  background-color: $gray-1;
  width: 16px;
}

.tags-data-trending::-webkit-scrollbar-track {
  background-color: $gray-1;
}

.tags-data-trending::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid $gray-1;
}

#graphContainer:fullscreen {
  @include fullGraphHeight();
}

#graphContainer:-webkit-full-screen {
  @include fullGraphHeight();
}

/* IE11 */
#graphContainer:-ms-fullscreen {
  @include fullGraphHeight();
}

#graphContainer:-moz-full-screen {
  @include fullGraphHeight();
}
