

.solidguage-analysis-container{
    width: 100%;
    display: flex;
    grid-column-gap: 5px;
}

.solidguage-analysis-data-container{
    min-width: 60px !important;
    border: 1px solid ;
    padding: 4px;
    border-radius: 4px;
}

.solidguage-analysis-category{
    font-weight: 500;
    font-size: 10px;
}

.solidguage-analysis-value{
    font-weight: 700;
    font-size: 12px;
}

.solidguage-analysis-percentage{
    font-weight: 400;
    font-size: 8px;
}