@import "styles/variables.scss";

.graph-container-trending {
  background: $inverse;
  margin-bottom: 8px;
  border: 1px solid $gray-4;

  .trending-graph-heading-tools {
    margin-right: 63px;
    display: flex;
    align-items: center;

    .ant-btn-round:hover {
      span {
        color: $geekblue-6;
      }
    }
  }

  .graph-live-tools {
    display: none; //Adding to remove live graph buttons
    position: relative;
    top: -15px;
    width: 100px;
    height: 20px;
    margin: 0 auto;
    background: $blue-1;
    border-radius: 8px;

    .graph-tools {
      display: flex;
      justify-content: center;
      align-items: center;

      .live-mode-play-btn {
        margin: 0px 5px;
      }
    }
  }
}
