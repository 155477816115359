@import "../../styles/variables.scss";

.header-container {
  z-index: 2;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  height: 54px;
  align-items: center;
  background-color: $geekblue-6;
  width: 100%;

  .ant-breadcrumb {
    align-self: center;
  }

  .bread-crumbs-container {
    display: flex;
    flex-direction: row;
    flex-basis: 40%;
  }

  .logo-container {
    display: flex;
    flex-basis: 30%;
    justify-content: center;
  }

  .info-container {
    display: flex;
    align-items: center;
    width: 100px;
    flex-basis: 30%;
    justify-content: flex-end;
    min-width: 500px;

    .clock-wrapper {
      margin-right: 24px;

      .clock-container {
        border: 1px solid $purple-3;
        box-sizing: border-box;
        border-radius: 16px;
        height: 24px;
        padding: 5px 9px;
        display: flex;
        align-items: center;
        color: $gray-1;

        span {
          font-size: 12px;
          line-height: 20px;
          color: $purple-1;
        }
      }
    }

    .right-icons-container {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0 0 0 12px;
      height: 54px;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 12px;
      }

      li:hover {
        background: $purple-1;

        .anticon svg {
          color: $blue-6;
        }
      }

      .icon {
        padding: 0 12px;
      }

      .icon-style {
        .icon-svg-fill {
          fill: $gray-1;
        }
      }
    }

    .toolTip {
      background: $gray-3;
      color: $primary-65;
    }

    .user-image-container {
      margin: 0 20px;
      cursor: pointer;
    }
  }
}

.user-menu-popover {
  left: calc(100vw - 188px) !important;

  .ant-popover-content {
    box-shadow: 0px 4px 4px $disabled;
    border-radius: 8px;

    .ant-popover-arrow {
      right: -74px;
    }

    .ant-popover-inner {
      max-height: 80px;
      width: 180px;
      border-radius: 8px;

      .ant-popover-inner-content {
        padding: 8px 16px;
      }

      .ant-popover-title {
        .profile-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 8px;

          .anticon svg {
            height: 32px;
            width: 32px;
            margin-right: 8px;
            color: $primary-65;
          }

          .ant-typography {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $primary-65;
          }

          .userImg {
            height: 20px;
            width: 22px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }

      p {
        margin-bottom: 0;
      }

      .signOut {
        align-items: flex-start;
        text-align: center;
        cursor: pointer;

        .ant-typography {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $secondary-45;
        }
      }
    }
  }
}

@media only screen and (max-width: 1375px) {
  .header-container {
    .bread-crumbs-container {
      flex-basis: 60%;
    }

    .logo-container {
      flex-basis: 20%;
    }

    .info-container {
      flex-basis: 20%;
    }
  }
}
