@import "styles/variables.scss";

.reason-code-header-container {
  padding: 12px;
  background: #FFFFFF;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-7;

  .header-text {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
  }

  .login-help-about {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px !important;
  
    .question-mark-circle {
      cursor: pointer;
      align-self: center;
    }
  
    svg {
      path {
        fill: $black-1;
      }
    }
  
    .connect-button {
      margin-right: 16px;
      .ant-tag {
        height: 24px;
        padding: 0.25px 16px;
        border-radius: 24px;
      }
    }
  
    .clock-wrapper {
      margin-right: 16px;
  
      .clock-container {
        border: 1px solid $black-1;
        color: $black-1;
        box-sizing: border-box;
        border-radius: 16px;
        height: 24px;
        padding: 5px 9px;
        display: flex;
        align-items: center;
        .anticon-clock-circle {
          margin-right: 6px;
        }
  
        span {
          color: $black-1;
        }
      }
    }
  }

  .upl-logo {
    width: 77px;
    height: 32px;
  }

}