@import "styles/variables.scss";

.filter-navigator {
  display: flex;
  gap: 8px;
  padding: 8px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $geekblue-5;
  border-top: 1px solid $geekblue-1;

  .anticon svg {
    color: $geekblue-1;
    width: 16px;
    height: 16px;
  }

  input::placeholder {
    color: #f0f5ff;
    opacity: 1;
  }

  .filter-input {
    border: 1px solid $geekblue-3;
    border-radius: 16px;
    background: $geekblue-5;
    height: 30px;

    .ant-input {
      color: $geekblue-1;
    }
  }

  .ant-input-affix-wrapper > input.ant-input {
    background: $geekblue-5;
  }

  ::placeholder {
    color: $geekblue-1;
    opacity: 1;
  }
}

.ant-menu-sub.ant-menu-inline {
  background: $geekblue-5;
}

.ant-menu-submenu > .ant-menu {
  background-color: $geekblue-5 !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $blue-5 !important;
}

.side-menu-tabs {
  padding: 8px 4px;
  .ant-menu {
    .childern-submenu {
      padding-left: 30px;
      li {
        .ant-menu-item {
          background: $geekblue-6;
          color: $gray-1;
        }
      }
    }
  }

  .menu-class {
    .submenu {
      .ant-menu-submenu-title {
        border-radius: 18px;
        .ant-menu-sub {
          .ant-menu-item-active {
            background: $geekblue-5;
            color: $gray-1;
          }
        }
      }
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    border-radius: 18px;
    margin: 0 0;
    padding-left: 16px !important;
  }

  .ant-menu-submenu-arrow {
    color: $geekblue-1;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }
  .ant-tabs-tab-active {
    border-bottom: 2px solid $geekblue-1;
  }
  .ant-tabs-nav-list {
    gap: 16px;
    width: 100%;
    padding: 0 16px;
    justify-content: center;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .ant-tabs-ink-bar-animated {
    display: none;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-tab {
    padding: 8px;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .ant-tabs-nav::before {
    border: none;
  }
  .anticon svg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: $gray-1;
  }
  .menu-bar-icon {
    vertical-align: middle;
    svg {
      margin-left: 3px;
      color: $geekblue-5;
      height: 18px;
      width: 18px;
    }
  }
  .ant-menu {
    border-radius: 18px;
    background: $geekblue-5;
  }

  .ant-menu-item {
    border-radius: 18px;

    width: 100% !important;
    svg {
      color: $geekblue-1;
    }
  }

  .ant-menu-submenu {
    svg {
      color: $geekblue-1;
    }

    border-radius: 18px;
  }

  .ant-menu-item:hover,
  .ant-menu-item-selected {
    background-color: $gray-1;

    svg path{
      stroke: $geekblue-6;
      stroke-width: 0.6px;
    }
  }

  .ant-menu-submenu:hover {
    background: $gray-1;

    .ant-menu-sub {
      background-color: $gray-1;
    }

    .ant-menu-title-content {
      color: $geekblue-6;
    }

    svg {
      color: $geekblue-6;
    }
  }

  .ant-menu-title-content {
    color: $geekblue-1;

    a {
      color: $geekblue-1;
    }
  }

  .ant-menu-item::after {
    content: none;
  }

  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    background: $gray-1;

    .ant-menu-submenu-arrow {
      color: $geekblue-6;
    }

    .ant-menu {
      background: $gray-1;
    }

    svg {
      margin-left: 3px;
      color: $geekblue-5;
    }

    .ant-menu-title-content {
      color: $geekblue-5;
    }
  }

  .ant-menu-item a:hover {
    color: $geekblue-6 !important;
  }

  .ant-tabs-tabpane {
    padding: 8px;
  }

  .anticon {
    margin-right: 0px;
  }

  .ant-menu-item-selected {
    background-color: $gray-1 !important;
  }

  .collapsed-active-tab {
    .ant-tabs-tab-active {
      width: 48px;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    .ant-tabs-tab {
      display: none;
    }

    .ant-tabs-nav-wrap {
      display: block !important;
    }

    .ant-tabs-nav-list {
      padding: 0px !important;
    }
  }

  .ant-menu-item-icon + span {
    margin-left: 8px !important;
  }
}

.collapsed-side-menu-tabs {
  @extend .side-menu-tabs;

  .ant-menu-item,
  .ant-menu-submenu-title,
  .ant-menu-item-selected {
    position: relative;
    svg {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.ant-menu-item-selected::after {
  border-right: none !important;
}