@import "styles/variables.scss";

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    text-align: left;
}

.ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search,.ant-select.ant-tree-select.ant-select-multiple.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
    .ant-select-selector {
      border-radius: 16px;
    }
  }
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,.ant-select:not(.ant-select-disabled):hover .ant-select-selector  {
    border-color: $gray-5!important;
    box-shadow: none!important;
}
.ant-select-tree-treenode-checkbox-checked{
    .ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal{
        background-color: $geekblue-1;
    }
    
}