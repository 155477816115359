@import "../../styles/variables.scss";

.policy-and-terms {
  display: flex;
  justify-content: center;
  position: relative;
  top: 24px;
}

.application-upl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 78%;

  .application-text {
    .ant-typography {
      margin-left: 8px;
      width: 272px;
      height: 32px;
      left: 32px;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: $black-1;
    }
  }
}
