@import "styles/variables.scss";

.switch-module-modal {
  .ant-modal-body {
    width: 708x;
    height: 294px;
    border-radius: 16px;
  }
  .ant-modal-content {
    border-radius: 16px;
  }
}

.switch-module-parent-container {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 24px 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.switch-module-header-container {
  height: 58px;
  width: 660px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.switch-module-data-wrapper {
  width: 660px;
  height: 205px;
  margin-top: 16px;
  padding-bottom: 8px;
}

.switch-module-data-inner-wrapper {
  display: flex;
  gap: 64px;
}

.switch-module-card-container {
  height: 175px;
  width: 175px;
  border-radius: 16px;
  border: 2px solid $black-3;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 44px 13px 44px 13px;
  margin-top: 8px;
}

.switch-module-card-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.switch-module-card-container:hover {
  cursor: pointer;
  border: 2px solid $geekblue-6;
  span {
    color: $geekblue-6;
    font-weight: 500;
  }

  svg path {
    stroke: $geekblue-6;
  }
}
