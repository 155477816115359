@import "styles/variables.scss";

.title {
  font-weight: 500;
  font-size: 16px;
}

.chart-container {
  padding: 8px;
  border: 1px solid $gray-5;
  width: 100%;
  border-radius: 12px;
  margin: 4px 2px 2px 2px;
  background-color: $gray-1;
}

.title-container {
  width: 100%;
  padding-left: 5px;
  align-items: center;
  display: flex;
  .anticon-expand-alt {
    border: 0.5px solid #d9d9d9;
    width: 26px;
    height: 16px;
    color: #8c8c8c;
    border-radius: 8px;
  }
  .anticon-shrink {
    border: 0.5px solid #d9d9d9;
    width: 26px;
    height: 16px;
    color: #8c8c8c;
    border-radius: 8px;
  }
}
.title-container-left {
  display: flex;
  flex-grow: 1;
  margin-bottom: 15px;
}

.title-container-right {
  cursor: pointer;
  display: flex;
}

.title-tooltip-icon {
  margin-left: 4px;
  color: $gray-7;
  margin-top: 2px;
}

.severity-container {
  padding-right: 12px;
}
