@import "styles/variables.scss";

.dropdownFilterCard-main-wrapper-container {
  width: 100%;
  height: 40px;
  padding: 4px 8px 4px 8px;
  display: grid;
  grid-template-columns: 1030px 1fr;
  border-left: none;
  border-right: none;
  align-content: center;
}

.dropdownFiltercard-right-container {
  margin-left: auto;
  display: flex;
}

.filtercomponent-left-container {
  display: flex;
  gap: 8px;
}

.ant-select-tree-checkbox-inner {
  border-radius: 50% !important;
}

.anticon.anticon-file.ant-select-tree-switcher-line-icon {
  display: none;
}

.ant-picker {
  border-radius: 16px;

  .ant-picker-input {
    flex-direction: row-reverse;
    gap: 8px;

    input {
      color: $secondary-45;
    }
  }
}

.dropdownFiltercard-timepicker {
  width: 305px;

  .ant-picker {
    width: 100%;
  }
}

.ant-select-selector {
  border-radius: 16px;
}

.alarm-event-date-container {
  width: 415px;
  height: 30px;
  display: grid;
  grid-template-columns: 1fr repeat(3, minmax(70px, 1fr));
  align-items: center;
  border: 1px solid $gray-5;
  border-radius: 16px;
  padding-left: 5px;
  color: $secondary-45;

  .ant-picker {
    height: 30px;
    width: 100%;
  }

  input {
    color: $secondary-45;
  }

  .border-left {
    border-left: 2px solid $gray-5;
  }
}

.filtercomponent-container {
  height: 40px;
  padding: 4px 8px 4px 8px;
  display: grid;
  grid-template-columns: 70% 1fr;
  grid-column-gap: 5px;
}